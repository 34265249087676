.ListFrom2022 h2 {
    color: white;
}

.ListFrom2022 .dayPanel {
    display: flex;
    width: 400px;
    flex-direction: row;
    margin: 0 auto 40px;

}

.ListFrom2022 .dayPanel .dayMonthLabel {
    display: flex;
    width: 120px;
    color: #ccc;

}

.ListFrom2022 .dayPanel .dayMonthLabel .spacer {
    flex: 1;
}

.ListFrom2022 .dayPanel .dayMonthLabel .monthLabel {
    /*width: 40px;*/
    writing-mode: vertical-lr;
    text-orientation: upright;
    height: auto;
    text-align: start;
    margin-top: 18px;
    width: auto;
    font-weight: bold;
}

.ListFrom2022 .dayPanel .dayMonthLabel .dayLabel {
    /*width: 100px;*/
    width: auto;
    margin: 8px 12px 0 0;
    font-size: 75px;
    line-height: 1;
    text-align: right;

}

.ListFrom2022 .dayPanel .movies {
    flex: 1;
}

.ListFrom2022 .dayPanel .movies p{
    align-content: flex-start;
    text-align: left;
}

.ListFrom2022 .dayPanel .movies p a {

    text-decoration: none;
    color: #CCC;
}