.line {
    display: flex;
    flex-direction: row;
    margin: 12px 0;
    padding: 12px 0;
    /*background-color: #511111;*/
    background-color: #333333;
    color: #CCC;
    font-size: 20px;
}

.line div.year {
    width: 60px;
    text-align: center;
    font-size: 12px;
    padding-top: 6px;
}

.line div.name {
    flex: 1;
    font-weight: bold;
    text-align: left;
}

.line div.name a {
    text-decoration: none;
    color: #CCC;
}

.line div.rating {
    width: 300px;
    height: 60px;
}

@media only screen and (max-width: 800px) {
    .line {
        display: block;
        overflow: hidden;
    }
    .line div.year {
        width: 100%;
        margin-bottom: 10px;
        text-align: center;
    }
    .line div.name {
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
    }
    .line div.rating {
        flex: 1;
        padding-bottom: 10px;
        text-align: center;
        margin: 0 auto;
    }
}

span.halfRating {
    background-image: url('../../pennywiseHalf.png');
    background-size: 60px 60px;
    float: left;
    width: 60px;
    height: 60px
}
span.fullRating {
    background-image: url('../../pennywiseFull.png');
    background-size: 60px 60px;
    float: left;
    width: 60px;
    height: 60px
}
