.day {
    display: flex;
    flex-direction: column;
    height: 80px;
}

.dayLabel {
    width: 100%;
    height: 16px;
    text-align: left;
    font-size: 12px;
    color: #CCC;
}

.dayCount {
    flex: 1;
    text-align: center;
    font-size: 42px;
    color: #CCC;
}