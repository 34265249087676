.calendar .week {
    display: flex;
    width: 100%;
    height: 80px;
}

.calendar .day {
    flex: 1;
    text-align: left;
    border: 1px solid #999;
    color: #999;
}

.calendar .day.september, .day.november {
    background-color: black;
}